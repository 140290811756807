import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
      url: "/v1/auth/login",
      method: "post",
      data,
    });
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {

    return ApiService.fetchData({
      url: "/v1/auth/forgot/password",
      method: "post",
      data,
    });
}

export async function apiValidateResetHash(data) {
  return ApiService.fetchData({
    url: "v1/auth/forgot/confirm",
    method: "post",
    data,
  });
}

export async function apiResetPassword (data) {
   return ApiService.fetchData({
     url: "/v1/auth/reset/password",
     method: "post",
     data,
   });
}
