import { PERSIST_STORE_NAME, REDIRECT_URL_KEY } from "constants/app.constant";
import { apiSignIn, apiSignUp } from "services/AuthService";
import { initialState, setUser } from "store/auth/userSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import {
  initialState as portfolioInitialState,
  setPortfolio,
} from "store/auth/portfolioSlice";
import { useDispatch, useSelector } from "react-redux";

import appConfig from "configs/app.config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        if (resp.data.hasError) {
          const err = resp.data.data;
          if (err.response.errors) {
            const error = Object.keys(err.response.errors)[0];

            return {
              status: "failed",
              message: err.response.errors[error],
            };
          } else {
            return {
              status: "failed",
              message: err.response.message || err.message,
            };
          }
        } else {
          const { token } = resp.data.data;
          dispatch(onSignInSuccess(token));
          if (resp.data.data.user) {
            dispatch(setUser(resp.data.data.user));
            dispatch(setPortfolio(resp.data.data.portfolios));
          }

          const redirectUrl = query.get(REDIRECT_URL_KEY);
          navigate(
            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
          );

          return {
            status: "success",
            message: "Signed In successfully",
          };
        }
      }

      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    dispatch(setPortfolio(portfolioInitialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    // await apiSignOut();
    const accessToken = window.localStorage.getItem(PERSIST_STORE_NAME);
    if (accessToken) {
      localStorage.removeItem(PERSIST_STORE_NAME);
      delete axios.defaults.headers.common.Authorization;
      handleSignOut();
      return {
        status: "success",
        message: "Signed Out successfully",
      };
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
