import { createSlice } from "@reduxjs/toolkit";

export const initialState = [];

export const portfolioSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setPortfolio: (_, action) => action.payload,
    userLoggedOut: () => initialState,
  },
});

export const { setPortfolio } = portfolioSlice.actions;

export default portfolioSlice.reducer;
