import { combineReducers } from '@reduxjs/toolkit'
import portfolio from "./portfolioSlice";
import session from "./sessionSlice";
import user from "./userSlice";

const reducer = combineReducers({
  session,
  user,
  portfolio,
});

export default reducer
